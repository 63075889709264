:root {
  --green100: #21754e;
  --green200: #399869;
  --green300: #76d9a7;
  --green400: #416067;
  --green600: #ebf4f6;
  --orange100: #f98002;
  --orange200: #fa962c;
  --orange300: #f8b062;
  --red100: #fb4f4f;
  --red200: #f86464;
  --red300: #f77a7a;
  --black: #000;
  --white: #fff;
  --text-dark: #000000;
  --grey100: #a5a5a5;
  --grey200: #d6d6d6;
  --grey300: #e6e6e6;
  --grey400: #e8e8e8;
  --grey500: #f2f2f2;
  --bg-dark: #222831;
  --bg-light: #fafafa;
  --profile-bg: #ebf4f6;
  --input-border: var(--grey200);

  --border: #d6d6d6;
  --border-grey100: #8e8e8e;
  --border-grey200: #d1d1d1;
  --orange100: #d65a31;
  --placeholder: #8e8e8e;

  --cancel-button-bg: #d5d5d5;
  --error-red: #fb4f4f;

  --rdp-day_button-width: 30px !important;
}
