.analyticCard-container {
  text-align: center;
  padding: 15px;
  background-color: var(--white);
  border: 1px solid var(--grey200);
  border-radius: 5px;
  width: fit-content;
  margin-right: 30px;
  width: 155px;
}

.AnalyticCard-title {
  margin-bottom: 4px;
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
}

.AnalyticCard-content {
  margin-bottom: 4px;
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-weight: 500;
}
