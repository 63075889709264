.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  position: absolute;
  width: 100%;
  height: 60px;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey400);
  z-index: 999;
  /*box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px 0px;*/
}

.navbar-logo-container {
  cursor: pointer;
}

.navbar-profile-container {
  position: absolute;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: var(--profile-bg);
  border: 1px solid var(--green400);
  cursor: pointer;
}

.navbar-profile-container:hover {
  /*border-bottom: 1px solid var(--black);*/
  transition: 0.15s all ease-out;
  /*margin-top: 5px;*/
}

.navbar-profile-title {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: var(--black);
}

.navbar-mobile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: var(--dark100);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px 0px;
}

.navbar-profile-expanded {
  position: absolute;
  top: 60px;
  right: 25px;
  background-color: var(--white);
  z-index: 999;
  width: fit-content;
  border-radius: 5px;
  padding: 16px 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px 0px;
  transition: all 0.2s ease-in;
  transform: scale(1);
  border: 1px solid var(--grey100);
}

.hide {
  display: block;
  height: 0;
  visibility: hidden;
  transition: 0.2s all;
  transform: scale(0);
  top: 25px;
  right: -20px;
}

.navbar-profile-expanded-wrapper {
  padding: 0 16px 12px 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey300);
}

.navbar-profile-expanded-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 5px;
  border: 1px solid var(--black);
  background-color: var(--profile-bg);
}

.navbar-profile-expanded-title {
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: var(--black);
}

.navbar-profile-expanded-name-container {
  margin-left: 24px;
}

.navbar-profile-expanded-name {
  font-size: 15px;
  color: var(--dark100);
  font-weight: 500;
}

.navbar-profile-expanded-email {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  color: var(--dark100);
  font-weight: 400;
  opacity: 0.8;
}

.navbar-profile-expanded-menu {
  position: relative;
  padding: 12px 0px 0px 24px;
  border-bottom: 1px solid var(--grey300);
  z-index: 999;
}

.navbar-profile-expanded-logout {
  font-size: 14px;
  color: var(--dark100);
  padding: 12px 0px 0px 24px;
}

.navbar-profile-expanded-menu-icon {
  font-size: 14px;
  color: var(--dark100);
  margin-right: 8px;
}

.navbar-profile-expanded-menu-title {
  font-family: "Exo 2", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: var(--dark100);
}

.navbar-profile-expanded-menu-item {
  display: flex;
  align-items: center;
  padding: 6px 0 6px 12px;
  margin-bottom: 4px;
  font-family: "Exo 2", sans-serif;
}

.navbar-profile-expanded-menu-item:hover {
  display: flex;
  align-items: center;
  padding: 6px 0 6px 12px;
  background-color: var(--grey300);
  width: 180px;
  border-radius: 5px;
  cursor: pointer;
}

.navbar-loginContainer {
  display: flex;
  align-items: center;
}

.navbar-login-signinButton {
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  margin-right: 30px;
  cursor: pointer;
}

.navbar-login-signinButton:hover {
  opacity: 0.7;
  transition: 0.3s all;
}

.navbar-login-signupButton {
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  margin-right: 30px;
  background-color: var(--black);
  color: var(--white);
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.navbar-login-signupButton:hover {
  opacity: 0.7;
  transition: 0.3s all;
}
