.adminDashboard-container {
}

.adminDashboard-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--grey200);
  background-color: var(--white);
  padding: 0px 80px;
  height: 70px;
}

.adminDashboard-title {
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.adminDashboard-logout {
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  font-size: 16px;
  background-color: var(--red100);
  color: var(--white);
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.adminDashboard-logout:hover {
  opacity: 0.7;
  transition: 0.3s all;
}

.adminDasboard-analyticContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 15px 80px;
}

.adminDashboard-usersContainer-actions {
  display: flex;
  justify-content: flex-end;
  margin: 15px 80px;
}

.adminDashboard-usersContainer-action-selected {
  padding: 5px 10px;
  margin-left: 20px;
  color: var(--white);
  background-color: var(--black);
  border: 1px solid var(--black);
  border-radius: 3px;
  cursor: default;
}

.adminDashboard-usersContainer-action {
  padding: 5px 10px;
  margin-left: 20px;
  background-color: var(--grey300);
  border: 1px solid var(--grey200);
  border-radius: 3px;
  cursor: pointer;
}

.adminDashboard-usersContainer-action:hover {
  opacity: 0.6;
  transition: 0.3s all;
}

.adminDashboard-usersContainer {
  margin: 15px 80px;
  border: 1px solid var(--grey200);
  border-radius: 5px;
  background-color: var(--white);
}

.adminDashboard-user-selectButton {
  background-color: var(--green400);
  padding: 5px 10px;
  border-radius: 3px;
  color: var(--white);
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.adminDashboard-user-selectButton:hover {
  opacity: 0.8;
  transition: 0.3s all;
}

.adminDashboard-deleteButton {
  background-color: var(--red200);
  padding: 5px 10px;
  border-radius: 3px;
  color: var(--white);
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.roleAdmin {
  text-align: center;
  width: 70px;
  background-color: var(--green600);
  border: 1px solid var(--green400);
  border-radius: 30px;
}

.roleUser {
  text-align: center;
  width: 70px;
  background-color: var(--white);
  border: 1px solid var(--grey300);
  border-radius: 30px;
}
