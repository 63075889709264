.contact-container {
  display: flex;
  justify-content: center;
  margin-top: 120px;
  padding: 20px;
}

.contact-form-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.contact-form-title {
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  font-size: 28px;
}

.contact-form-field {
}
.contact-form-field-label {
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}

.contact-form-field-input {
  height: 40px;
  width: 90%;
  border: 1px solid var(--grey200);
  padding: 10px;

  @media screen and (width < 992px) {
    width: 95%;
  }
}

.contact-form-field-textarea {
  width: 90%;
  height: 250px;
  border: 1px solid var(--grey200);
  padding: 10px;
}

.contact-form-field-button {
  text-align: center;
  padding: 8px 30px;
  background-color: var(--black);
  color: var(--white);
  width: fit-content;
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form-field-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 95%;
}

.contact-form-field-button:hover {
  opacity: 0.75;
  transition: 0.3s all;
}

.contact-form-askContainer {
  display: flex;
  align-items: flex-start;
  width: 450px;
  min-width: 350px;
}

.contact-form-ask-title {
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  font-size: 22px;
}

.contact-form-ask-responseContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff8f0;
  border: 1px solid var(--orange200);
  border-radius: 5px;
  width: fit-content;
  height: 40px;
  margin-right: 5%;
}

.contact-form-ask-response-title {
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: var(--orange100);
}

.contact-form-ask-response-time-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-form-ask-response-time {
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  width: fit-content;
  color: var(--black);
  padding: 5px 15px;
  border-radius: 5px;
}
