
.ant-form-item {
    margin-bottom: 15px;
}

.ant-form-item .ant-form-item-label >label {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Exo 2', sans-serif !important;
    color: var(--text-dark);
}

.ant-btn span {
    font-family: 'Exo 2', sans-serif;
}

.ant-btn {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.ant-btn:hover {
    
}

.ant-input {
    border-radius: 3px;
    height: 40px !important;
}

.ant-input::before {
    background-color: var(--white) !important;
}

.ant-input::after {
    background-color: var(--white) !important;
}

.ant-input-outlined {
    height: 40px !important;
    border-width: 1px;
    border-style: solid;
    border-color: var(--grey300);
}

.ant-input-outlined:hover {
    /*border-color: var(--bg-dark) !important;*/
}

.ant-input-outlined:focus {
    /*border-color: var(--bg-dark) !important;    */
}

.ant-input-outlined:focus-within {
    box-shadow: 0 0 0 0px #acabab;
}

.ant-input-affix-wrapper {
    height: 45px !important;
    border-radius: 3px;
    border-width: 1px;
}

.ant-input-affix-wrapper >input.ant-input {
    height: 25px !important;
}

.ant-input-affix-wrapper:focus-within {
    border: 1px solid var(--grey300);
}


.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--bg-dark);
    border-color: var(--bg-dark);
}

.ant-checkbox-checked:hover {
    border-color: var(--bg-dark);
}


.ant-checkbox-inner:hover {
    border-color: #000;
}

.ant-checkbox-checked .ant-checkbox-inner:hover {
    border-color: #000;
}

.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #000;
    border-color: #000;
}

.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #000;
}

.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner:hover {
    border-color: #000;
}

.ant-checkbox-wrapper:hover {
    border-color: #000;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
    border-color: #000;
}

.ant-pagination-item-active {
    border: 1px solid var(--black) !important;
    background-color: var(--black) !important;
}

.ant-pagination-item-active a {
    color: var(--white) !important;
}